import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"

import Testimonials from "../../components/Layout/Testimonials"
import Footer from "../../components/Layout/Footer"

const NotFoundPage = ({ intl, data }) => {
  const returnLink = () => {
    let str = ""

    if (process.env.GATSBY_ACTIVE_ENV !== "general")
      str += "/" + process.env.GATSBY_ACTIVE_ENV

    if (process.env.GATSBY_ACTIVE_ENV === "usd") {
      str = "/shop/lyneup"
    } else if (process.env.GATSBY_ACTIVE_ENV === "gbp") {
      str += "/shop/lyneup"
    } else if (process.env.GATSBY_ACTIVE_ENV === "cad") {
      if (intl.locale === "en") str += "/shop/lyneup"
      else str = `/${intl.locale}/shop/lyneup`
    } else {
      if (intl.locale === "fr") str += "/shop/lyneup"
      else str += `/${intl.locale}/shop/lyneup`
    }
    return str
  }

  return (
    data &&
    data.allDirectusFooterTranslation.nodes.length > 0 && (
      <Fragment>
        <Section>
          {data.allDirectusErrorPageTranslation.nodes[0].desktop_image && (
            <DesktopImageWrapper>
              <Image
                objectFit="contain"
                image={
                  data.allDirectusErrorPageTranslation.nodes[0].desktop_image
                    .localFile.childImageSharp.gatsbyImageData
                }
                alt=""
              />
            </DesktopImageWrapper>
          )}
          {data.allDirectusErrorPageTranslation.nodes[0].mobile_file && (
            <MobileImageWrapper>
              <Image
                objectFit="contain"
                image={
                  data.allDirectusErrorPageTranslation.nodes[0].mobile_file
                    .localFile.childImageSharp.gatsbyImageData
                }
                alt=""
              />
            </MobileImageWrapper>
          )}
          <Wrapper>
            <TextWrapper>
              {data.allDirectusErrorPageTranslation.nodes[0].heading && (
                <Heading
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusErrorPageTranslation.nodes[0].heading,
                  }}
                  lang={intl.locale}
                />
              )}
              {data.allDirectusErrorPageTranslation.nodes[0].heading && (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: data.allDirectusErrorPageTranslation.nodes[0].text.replace(
                      "link_lyneup",
                      returnLink()
                    ),
                  }}
                />
              )}
              {data.allDirectusErrorPageTranslation.nodes[0].link_text && (
                <Button to="/">
                  {data.allDirectusErrorPageTranslation.nodes[0].link_text}
                </Button>
              )}
            </TextWrapper>
          </Wrapper>
        </Section>

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`

const DesktopImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileImageWrapper = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;

  @media (max-width: 550px) {
    display: flex;
  }
`

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin: 0;
  min-height: 300px;
`

const Wrapper = styled.div`
  max-width: 1380px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  padding: 0px 20px;
  top: 250px;

  @media (max-width: 550px) {
    top: auto;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 35px;
  background: rgba(255, 255, 255, 0.77);
  max-width: 640px;
  color: #262626;

  @media (max-width: 850px) {
    padding: 25px;
  }

  @media (max-width: 550px) {
    padding: 15px;
    max-width: 270px;
  }
`

const Heading = styled.div`
  font-size: ${({ lang }) => (lang === "en" ? "40px" : "60px")};
  margin-bottom: 10px;
  font-family: "BebasNeueBold";
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;

  @media (max-width: 850px) {
    font-size: 40px;
  }

  @media (max-width: 550px) {
    font-size: 24px;
  }
`

const Text = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: "Gotham";
  line-height: 1.2;

  p {
    margin-bottom: 0px;
  }

  @media (max-width: 850px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 12px;
  }

  a {
    text-decoration: underline;
  }
`

const Button = styled(Link)`
  background-color: #262626;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 10px 25px;

  :hover {
    color: #fff;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusErrorPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        heading
        link_text
        text
        desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 100)
            }
          }
        }
        mobile_file {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
  }
`

export default injectIntl(NotFoundPage)
